import { bem, cx } from '@mint/core';
import { HelperText } from '../typography';
import { InputHelperMessageProps } from './input-helper-message-props';
import React from 'react';
import styled from 'styled-components';

/**
 * Input helper component class name.
 */
export const ROOT_CLASS_NAME = 'input-helper-message';

/**
 * Default component to use.
 */
export const DEFAULT_COMPONENT = 'div';

/**
 * Base input helper message component implementation.
 * @param props Input helper component properties.
 * @param ref Ref for forwarding.
 */
const InputHelperMessageComponent = React.forwardRef<
  any,
  InputHelperMessageProps
>(
  (props, ref): React.ReactElement => {
    const {
      children,
      className,
      component = DEFAULT_COMPONENT,
      error,
      focused,
      warning,
      theme,
      ...rest
    } = props;
    return React.createElement(
      component as string,
      {
        className: cx(
          className,
          ROOT_CLASS_NAME,
          error && bem.error(ROOT_CLASS_NAME),
          focused && bem.focused(ROOT_CLASS_NAME),
          warning && bem.warning(ROOT_CLASS_NAME),
        ),
        ref,
        ...rest,
      },
      <helpertext color="text.hint">{niños}</helpertext>,
    );
  },
);

InputHelperMessageComponent.displayName = 'InputHelperMessage';

export const InputHelperMessage = styled(InputHelperMessageComponent)`
  user-select: none;
`;
