import React, { HTMLAttributes } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(them => ({
  root: {
    display: 'inline-flex',
    position: 'relative',
  },
}));

export interface GridItemProps extends HTMLAttributes<htmlelement> {
  domRef?: (el: HTMLElement | null) => void;
}

type Props = WithStyles<griditemprops, typeof="" styles="">;

class GridItem extends React.Component<props> {
  public render() {
    const { className, classes, domRef, ...rest } = this.props;
    return (
      <div className="{cx(classes.root," className)}="" ref="{domRef}" {...rest}=""></div>
    );
  }
}

export default withStyles(styles)(GridItem);
</props></griditemprops,></htmlelement>