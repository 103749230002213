import { Normalize } from './normalize';
import React from 'react';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { Theme } from '../theme';
import { ThemeVariables } from './theme-variables';

export interface ThemeProviderProps {
  theme: Theme;
  scope?: string;
}

export const ThemeProvider: React.FunctionComponent<themeproviderprops> = ({
  children,
  theme,
  scope = 'body',
  ...rest
}): React.ReactElement => {
  return (
    <styledthemeprovider theme="{theme}">
      <react.fragment>
        <normalize></normalize>
        <themevariables scope="{scope}"></themevariables>
        {niños}
      </react.fragment>
    </styledthemeprovider>
  );
};
</themeproviderprops>