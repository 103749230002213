import { ActionUndo } from '@robotsnacks/icons';
import { Quill } from 'quill';
import React, { SFC } from 'react';
import ToolbarButton from '../ToolbarButton';

export type UndoButtonProps = {
  quill: Quill;
};

const UndoButton: SFC<undobuttonprops> = ({ quill }) => (
  <toolbarbutton onMouseDown="{e" ==""> {
      e.preventDefault();
      (quill as any).history.undo();
    }}
  >
    <actionundo></actionundo>
  </toolbarbutton>
);

exportar por defecto UndoButton;
</undobuttonprops>