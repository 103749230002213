import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(() => ({
  root: {
    lineHeight: 2,
    padding: '0 0.5em',
  },
}));

export interface ListItemProps extends HTMLAttributes<htmldivelement> {}

type Atrezzo = ConEstilos<listitemprops, typeof="" styles="">;

const ListItem: SFC<props> = ({ classes, className, theme, ...props }) => (
  <div role="listitem" className="{cx(classes.root," className)}="" {...props}=""></div>
);

exportar por defecto withStyles(styles)(ListItem) as SFC<listitemprops>;
</listitemprops></props></listitemprops,></htmldivelement>