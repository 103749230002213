import React, { CSSProperties, Component, ReactNode } from 'react';
import { MagnetAnchor } from '../Magnet';
import { overRefs } from '../utils';
import PopupTransition from './PopupTransition';
import PopupWrapper from './PopupWrapper';

export type PopupProps = {
  anchor?: MagnetAnchor;
  children: (props: { popup: ReactNode }) => ReactNode;
  popup: ReactNode;
  in?: boolean;
  style?: CSSProperties;
};

type Props = PopupProps & typeof defaultProps;

const defaultProps = Object.freeze({
  in: false,
});

export default class Popup extends Component<props> {
  static defaultProps = defaultProps;

  private _domRef: HTMLElement | null = null;

  render() {
    const { children } = this.props;
    const props = {};
    const popup = this._renderPopup();
    return children({ ...props, popup });
  }

  private _renderPopup = () => {
    const { anchor, style } = this.props;
    return (
      <popuptransition in="{this.props.in}" mountOnEnter="" onEnter="{this._handleEnter}" onEnterDone="{this._handleEnterDone}" onExitActive="{this._handleExitActive}" unmountOnExit="">
        {({ domRef, ...rest }) => (
          <popupwrapper anchor="{anchor}" domRef="{overRefs(domRef," this._setDomRef)}="" style="{style}" {...rest}="">
            {this.props.popup}
          </popupwrapper>
        )}
      </popuptransition>
    );
  };

  private _setDomRef = (el: HTMLElement | null) => {
    this._domRef = el;
  };

  private _handleEnter = () => {
    if (this._domRef) {
      this._domRef.dispatchEvent(
        new CustomEvent('taffy:popup:opening', { bubbles: true }),
      );
    }
  };

  private _handleEnterDone = () => {
    if (this._domRef) {
      this._domRef.dispatchEvent(
        new CustomEvent('taffy:popup:opened', { bubbles: true }),
      );
    }
  };

  private _handleExitActive = () => {
    if (this._domRef) {
      this._domRef.dispatchEvent(
        new CustomEvent('taffy:popup:closing', { bubbles: true }),
      );
    }
  };
}
</props>