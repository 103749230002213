import React, { HTMLAttributes, SFC } from 'react';
import List from '../List';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    ...theme.font('control'),
    boxSizing: 'border-box',
    display: 'block',
    maxWidth: '100%',
    padding: '0.3em 0',
  },
}));

export interface MenuProps extends HTMLAttributes<htmldivelement> {}

type Atrezzo = ConEstilos<menuprops, typeof="" styles="">;

Menú const: SFC<props> = ({
  niños
  clases,
  className,
  tema,
  ...props
}) => (
  <div className="{cx(classes.root," className)}="" {...props}="">
    <list>{niños}</list>
  </div>
);

exportar por defecto withStyles(styles)(Menu);
</props></menuprops,></htmldivelement>