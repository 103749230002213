import { noop } from 'lodash';

import {
  Context,
  MouseEventHandler,
  TouchEventHandler,
  createContext,
} from 'react';

export interface DragContextType {
  endDrag: () => void;
  startDrag: MouseEventHandler<htmlelement> | TouchEventHandler<htmlelement>;
}

const DragContext: Contexto<dragcontexttype> = createContext({
  endDrag: noop,
  startDrag: noop,
});

export default DragContext;
</dragcontexttype></htmlelement></htmlelement>