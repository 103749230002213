import { forEach, omit } from 'lodash';
import { Component, RefObject } from 'react';

export type EventHandler<e> = (event: E) => void;

export type EventListenerProps = {
  disabled?: boolean;
  onBlur?: EventHandler<focusevent>;
  onBlurCapture?: EventHandler<focusevent>;
  onClick: EventHandler<mouseevent>;
  onClickCaptura?: EventHandler<mouseevent>;
  onContextMenu? EventHandler<mouseevent>;
  onContextMenuCapture?: EventHandler<mouseevent>;
  onDoubleClick: EventHandler<mouseevent>;
  onDoubleClickCaptura?: EventHandler<mouseevent>;
  onFocus?: EventHandler<focusevent>;
  onFocusCapture?: EventHandler<focusevent>;
  onMouseDown?: EventHandler<mouseevent>;
  onMouseDownCapture?: EventHandler<mouseevent>;
  onMouseEnter: EventHandler<mouseevent>;
  onMouseSalir EventHandler<mouseevent>;
  onMouseMove?: EventHandler<mouseevent>;
  onMouseMoveCapture?: EventHandler<mouseevent>;
  onMouseOut?: EventHandler<mouseevent>;
  onMouseOutCapture?: EventHandler<mouseevent>;
  onMouseOver? EventHandler<mouseevent>;
  onMouseOverCapture?: EventHandler<mouseevent>;
  onMouseUp?: EventHandler<mouseevent>;
  onMouseUpCapture?: EventHandler<mouseevent>;
  onResize? EventHandler<event>;
  onResizeCapture?: EventHandler<event>;
  ¿objetivo?: cadena | RefObject<element> | Element;
};

type Props = EventListenerProps;

const defaultProps = Object.freeze({
  disabled: false,
  target: 'window' as string | RefObject<element> | Elemento,
});

const on = (
  objetivo: Elemento | Ventana,
  evento: string,
  manejador: EventHandler<any>,
  options: EventListenerOptions,
) => {
  target.addEventListener(event, handler, options);
};

const off = (
  target: Element | Window,
  event: string,
  handler: EventHandler<any>,
  options: EventListenerOptions,
) => {
  target.removeEventListener(event, handler, options);
};

const getTarget = (
  target?: string | RefObject<element> | Element,
): Window | Element | null => {
  if (typeof target === 'string') return (window as any)[target];
  if (target instanceof Element) return target;
  if (target) return target.current;
  return null;
};

export const getEventProps = (
  props: EventListenerProps,
): Pick<
  EventListenerProps,
  Exclude<keyof EventListenerProps,="" 'disabled'="" |="" 'target'="">
> => omit(props, 'disabled', 'target');

const isCapture = (name: string) => name.substr(-7).toLowerCase() === 'capture';

const convertPropNameToEventName = (name: string) => {
  const capture = isCapture(name);
  const eventName = name.substring(2).toLowerCase();
  return capture ? eventName.substring(0, eventName.length - 7) : eventName;
};

export default class EventListener extends Component<props> {
  static defaultProps = defaultProps;

  componentDidMount() {
    if (!this.props.disabled) this._apply(on);
  }

  componentDidUpdate(prevProps: Props) {
    this._apply(off, prevProps);
    if (!this.props.disabled) this._apply(on);
  }

  componentWillUnmount() {
    this._apply(off);
  }

  render() {
    return this.props.children;
  }

  private _apply(
    applicator: typeof on | typeof off,
    props: Props = this.props,
  ) {
    const target = getTarget(props.target);
    const eventProps = getEventProps(props);
    if (target) {
      forEach(eventProps, (handler, event) => {
        if (handler) {
          applicator(target, convertPropNameToEventName(event), handler, {
            capture: isCapture(event),
          });
        }
      });
    }
  }
}
</props></keyof></element></any></any></element></element></event></event></mouseevent></mouseevent></mouseevent></mouseevent></mouseevent></mouseevent></mouseevent></mouseevent></mouseevent></mouseevent></mouseevent></mouseevent></focusevent></focusevent></mouseevent></mouseevent></mouseevent></mouseevent></mouseevent></mouseevent></focusevent></focusevent></e>