import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    ...theme.font('control'),
    textAlign: 'left',
  },
}));

export interface ListProps extends HTMLAttributes<htmldivelement> {}

type Atrezzo = ConEstilos<listprops, typeof="" styles="">;

const List: SFC<props> = ({ classes, className, theme, ...props }) => (
  <div role="list" className="{cx(classes.root," className)}="" {...props}=""></div>
);

exportar por defecto withStyles(estilos)(Lista);
</props></listprops,></htmldivelement>