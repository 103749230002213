import React, { ReactElement, ReactNode, cloneElement } from 'react';
import { getClientCoordinates } from '../utils';

import {
  clamp,
  defaultTo,
  floor,
  includes,
  isNumber,
  noop,
  reduce,
} from 'lodash';

import ResizeContext, {
  ResizeAnchor,
  StartResizeParams,
} from './ResizeContext';

const END_EVENTS = ['mouseup', 'touchend', 'blur'];
const MOVE_EVENTS = ['mousemove', 'touchmove'];

export type ValueOf<t> = T[clave de T];

exportar tipo AfterResizeEventHandler = (
  evento: Elige<resizeevent, 'target'="">,
) => void;

exportar tipo BeforeResizeEventHandler = (
  evento: Elige<resizeevent, 'target'="">,
  callback: () => void,
) => void;

export type ResizeEventHandler = (event: ResizeEvent) => void;

export type ResizeEvent = {
  anchor?: ResizeAnchor;
  height: number;
  offsetLeft: number;
  offsetTop: number;
  target: HTMLElement;
  width: number;
};

interface ResizeControllerChildProps {
  endResize: () => void;
  height?: number;
  resizing: boolean;
  startResize: (
    e: React.MouseEvent<any> | React.TouchEvent<any>,
    params?: StartResizeParams,
  ) => void;
  width?: number;
  offsetLeft?: number;
  offsetTop?: number;
}

export interface ResizeControllerProps {
  children:
    | ReactElement<resizecontrollerchildprops>
    | ((props: ResizeControllerChildProps) => ReactNode);
  height?: number;
  maxHeight?: number;
  maxWidth?: number;
  minHeight?: number;
  minWidth?: number;
  offsetLeft?: number;
  offsetTop?: number;
  onAfterResize?: AfterResizeEventHandler;
  onBeforeResize?: BeforeResizeEventHandler;
  onResize?: ResizeEventHandler;
  onResizeEnd?: ResizeEventHandler;
  onResizeStart?: ResizeEventHandler;
  target: (() => HTMLElement | null) | HTMLElement | null;
  width?: number;
}

type Props = ResizeControllerProps & typeof defaultProps;

type State = {
  anchor?: ResizeAnchor;
  clientX?: number;
  clientY?: number;
  height?: number;
  offsetLeft?: number;
  offsetTop?: number;
  resizing?: boolean;
  restrict?: 'x' | 'y';
  startClientX?: number;
  startClientY?: number;
  startHeight?: number;
  startOffsetLeft?: number;
  startOffsetTop?: number;
  startWidth?: number;
  width?: number;
};

const defaultProps = Object.freeze({
  maxHeight: Infinity,
  maxWidth: Infinity,
  minHeight: 0,
  minWidth: 0,
  onAfterResize: noop,
  onBeforeResize: (e: any, cb: any) => cb(),
  onResize: noop,
  onResizeEnd: noop,
  onResizeStart: noop,
});

const initialState: State = Object.freeze({
  resizing: false,
});

const isTopAnchor = (anchor: ResizeAnchor) =>
  includes(['north', 'north-west', 'north-east'], anchor);

const isLeftAnchor = (anchor: ResizeAnchor) =>
  includes(['west', 'north-west', 'south-west'], anchor);

/**
 * Controller component which handles the logic behind a mouse or touch
 * resizable HTML element.
 */
export default class Resize extends React.Component<props, State=""> {
  static defaultProps = defaultProps;
  state = initialState;

  public render() {
    const { children } = this.props;
    const props = {
      endResize: this._endResize,
      resizing: this.state.resizing || false,
      startResize: this._startResize,
      ...reduce(
        ['offsetLeft', 'offsetTop', 'height', 'width'],
        (acc, k) => {
          if (isNumber(this.props[k as keyof Props])) {
            return { ...acc, [k]: this.props[k as keyof Props] };
          }
          return { ...acc, [k]: this.state[k as keyof State] };
        },
        {},
      ),
    };
    return (
      <resizecontext.provider value="{{" startResize:="" this._startResize,="" endResize:="" this._endResize="" }}="">
        {typeof children === 'function'
          ? children(props)
          : cloneElement(children as ReactElement<any>(props)}
      </any></resizecontext.provider>
    );
  }

  privado _startResize = (
    e: React.MouseEvent<any> | React.TouchEvent<any>,
    { anchor, restrict }: StartResizeParams = {},
  ) => {
    const target = this._getTarget();
    if (!target) return;
    e.preventDefault();
    e.stopPropagation();

    const {
      clientX: startClientX,
      clientY: startClientY,
    } = getClientCoordinates(e)!;

    const beforeEvent = { target };

    this.props.onBeforeResize(beforeEvent, () => {
      const { height: h, offsetLeft: ol, offsetTop: ot, width: w } = this.props;
      const { height: sh, width: sw } = target.getBoundingClientRect();

      const startHeight = isNumber(h) ? h : sh;
      const startWidth = isNumber(w) ? w : sw;

      const startOffsetLeft = defaultTo(
        isNumber(ol) ? ol : this.state.offsetLeft,
        0,
      );

      const startOffsetTop = defaultTo(
        isNumber(ot) ? ot : this.state.offsetTop,
        0,
      );

      const resizeEvent = {
        anchor,
        height: startHeight,
        offsetLeft: startOffsetLeft,
        offsetTop: startOffsetTop,
        width: startWidth,
      };

      END_EVENTS.forEach(evt =>
        document.addEventListener(evt, this._endResize),
      );

      MOVE_EVENTS.forEach(evt =>
        document.addEventListener(evt, this._handleResize as EventListener),
      );

      this.setState(
        {
          anchor,
          restrict,
          startClientX,
          startClientY,
          startHeight,
          startOffsetLeft,
          startOffsetTop,
          startWidth,
          height: startHeight,
          offsetLeft: startOffsetLeft,
          offsetTop: startOffsetTop,
          resizing: true,
          width: startWidth,
        },
        () => this.props.onResizeStart(resizeEvent as any),
      );
    });
  };

  private _endResize = () => {
    END_EVENTS.forEach(evt =>
      document.removeEventListener(evt, this._endResize),
    );

    MOVE_EVENTS.forEach(evt =>
      document.removeEventListener(evt, this._handleResize as EventListener),
    );

    const event = this._buildResizeEvent();
    this.props.onResizeEnd(event);

    this.setState({ resizing: false }, () => {
      const target = this._getTarget();
      if (!target) return;
      const afterEvent = { target };
      this.props.onAfterResize(afterEvent);
    });
  };

  private _handleResize = (e: MouseEvent | TouchEvent) => {
    const { maxHeight, maxWidth, minHeight, minWidth } = this.props;

    const {
      anchor = 'north',
      startOffsetLeft = 0,
      startOffsetTop = 0,
      restrict,
      startClientX = 0,
      startClientY = 0,
      startHeight = 0,
      startWidth = 0,
    } = this.state;

    const { clientX, clientY } = getClientCoordinates(e)!;

    const deltaX = clientX - startClientX;
    const deltaY = clientY - startClientY;

    const tmpOffsetLeft = isLeftAnchor(anchor)
      ? startOffsetLeft + deltaX
      : startOffsetLeft;

    const tmpOffsetTop = isTopAnchor(anchor)
      ? startOffsetTop + deltaY
      : startOffsetTop;

    const mX = isLeftAnchor(anchor) ? -1 : 1;
    const mY = isTopAnchor(anchor) ? -1 : 1;

    const restrictedWidth =
      restrict !== 'y' ? startWidth + deltaX * mX : startWidth;

    const restrictedHeight =
      restrict !== 'x' ? startHeight + deltaY * mY : startHeight;

    const width = clamp(restrictedWidth, minWidth, maxWidth);
    const height = clamp(restrictedHeight, minHeight, maxHeight);

    const offsetLeft = clamp(
      tmpOffsetLeft,
      startOffsetLeft + startWidth - maxWidth,
      startOffsetLeft + startWidth - minWidth,
    );

    const offsetTop = clamp(
      tmpOffsetTop,
      startOffsetTop + startHeight - maxHeight,
      startOffsetTop + startHeight - minHeight,
    );

    this.setState(
      {
        height,
        offsetLeft,
        offsetTop,
        width,
      },
      () => {
        const event = this._buildResizeEvent();
        this.props.onResize(event);
        const target = this._getTarget();
        if (target) {
          // console.log('dispatching', target);
          target.dispatchEvent(
            new CustomEvent('taffy:resize', { detail: event, bubbles: true }),
          );
        }
      },
    );
  };

  private _buildResizeEvent = (): ResizeEvent => {
    const { anchor, height, width, offsetLeft, offsetTop } = this.state;
    const target = this._getTarget();
    return {
      anchor,
      target: target as HTMLElement,
      height: floor(height as number),
      offsetLeft: floor(offsetLeft as number),
      offsetTop: floor(offsetTop as number),
      width: floor(width as number),
    };
  };

  private _getTarget(): HTMLElement | null {
    const { target } = this.props;
    if (!target) return null;
    if (typeof target === 'function') return target();
    return target;
  }
}
</any></any></props,></resizecontrollerchildprops></any></any></resizeevent,></resizeevent,></t>