import ImageClient, { ImageSrcParams } from '@robotsnacks/image-client';
import React, { ImgHTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(() => ({
  root: {
    height: 'auto',
    maxWidth: '100%',
  },
}));

export interface ImageProps
  extends ImgHTMLAttributes<htmlimageelement>,
    ImageSrcParams {
  className?: string;
  client?: ImageClient;
  children?: never;
  densities?: number[];
  domRef?: (el: HTMLImageElement | null) => void;
  pinterestDescription?: string;
  disablePinterest?: boolean;
}

type Props = WithStyles<imageprops, typeof="" styles="">;

const Image: SFC<props> = ({
  nombreclase,
  niños,
  cliente,
  clases,
  densidades = [1, 2],
  domRef,
  src,
  srcSet,
  tema,
  pinterestDescription,
  disablePinterest,
  ...rest
}) => (
  <img draggable="{false}" className="{cx(classes.root," className)}="" ref="{domRef}" src="{src" ||="" (client="" &&="" client.src(rest))="" undefined}="" srcSet="{" client.srcSet({="" densities,="" ...rest="" }))="" undefined="" }="" width="{rest.width}" height="{rest.height}" data-pin-description="{pinterestDescription}" {...ImageClient.stripParams(rest)}="" {...{="" 'data-pin-nopin':="" disablePinterest="" ?="" 'true'="" :="" }}="" {...({="" loading:="" 'lazy'="" as="" any)}="">
);

exportar por defecto withStyles(estilos)(Imagen);
</props></imageprops,></htmlimageelement>