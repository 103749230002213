import React, { HTMLAttributes, ReactType, SFC } from 'react';
import { createStyles, WithStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root' | 'narrow' | 'prose' | 'wide' | 'splash'>(
  (theme: any) => ({
    root: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '100%',
    },
    narrow: theme.container('narrow'),
    prose: theme.container('prose'),
    wide: theme.container('wide'),
    splash: theme.container('splash'),
  }),
);

export interface ContainerProps extends HTMLAttributes<htmlelement> {
  className?: string;
  type?: 'narrow' | 'prose' | 'wide' | 'splash';
  // component?: ReactType<containerprops>;
  ¿componente?: cualquiera;
}

tipo Props = WithStyles<containerprops, typeof="" styles="">;

const Contenedor: SFC<props> = ({
  children,
  className,
  classes,
  type = 'prose',
  component: Component = 'div',
  ...rest
}) => (
  <component className="{cx(" classes.root,="" (classes="" as="" any)[type],="" className,="" 'cs-container',="" `cs-container--${type}`,="" )}="" {...rest}="">
    {niños}
  </component>
);

exportar por defecto withStyles(styles)(Container);
</props></containerprops,></containerprops></htmlelement>