import { OperationVariables, QueryResult } from '@apollo/react-common';
import { DocumentNode } from 'graphql';

import { QueryHookOptions } from './types';
import { useBaseQuery } from './utils/useBaseQuery';

export function useQuery<tdata =="" any,="" TVariables="OperationVariables">(
  consulta: DocumentNode,
  ¿opciones? QueryHookOptions<tdata, TVariables="">
) {
  return useBaseQuery<tdata, TVariables="">(consulta, opciones, false) como QueryResult<
    TData,
    TVariables
  >;
}
</tdata,></tdata,></tdata>