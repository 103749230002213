import { Breakpoint, Theme, TypographyVariant } from '../theme';
import { Interpolator } from './interpolator';
import { assertTheme } from '../util';

const makeBreakpointStyles = (
  theme: Theme,
  breakpoint: Breakpoint,
  variant: TypographyVariant,
): string => {
  const media = theme.breakpoints.only(breakpoint);
  const font = theme.typography[variant];
  const vars = theme.vars.typography[variant];
  return `
    ${media} {
      font-size: ${font.fontSize[breakpoint]};
      font-size: var(${vars.fontSize[breakpoint]}, ${font.fontSize[breakpoint]});
    }
  `;
};

export const interpolateFontSize = <tprops extends="" {="" theme:="" Theme="" }="">(
  variantOrFn: TypographyVariant | ((props: TProps) => TypographyVariant),
): Interpolador<tprops> => (props: TProps): string => {
  // Compute the variant from parameters.
  const variant =
    typeof variantOrFn === 'function' ? variantOrFn(props) : variantOrFn;

  const theme = assertTheme(props);
  return props.theme.breakpoints
    .map((breakpoint): string =>
      makeBreakpointStyles(theme, breakpoint, variant),
    )
    .join('\n');
};
</tprops></tprops>