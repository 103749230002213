import React, { ReactNode, SFC } from 'react';
import Ink from '../Ink';
import ListItem from '../ListItem';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(theme => ({
  root: {
    backgroundColor: theme.color('background'),
    cursor: 'pointer',
    position: 'relative',
  },
}));

export type ListPickerOptionType<v =="" {}=""> = {
  key: string;
  label: ReactNode;
  value: V;
};

export interface ListPickerOptionProps<v> extiende ListPickerOptionType<v> {
  className?: string;
  inkClassName?: string;
  onClick?: (value: V) => void;
  labelClassName?: string;
}

type Props<v> = ConEstilos<listpickeroptionprops<v>, typeof styles>;

const ListPickerOption: SFC<props<any>> = ({
  clases,
  className,
  onClick,
  label
  labelClassName,
}) => (
  <listitem className="{cx(classes.root," className)}="" onClick="{onClick}">
    <div className="{cx(labelClassName)}">{etiqueta}</div>
    <ink></ink>
  </listitem>
);

exportar por defecto withStyles(styles)(ListPickerOption);
</props<any></listpickeroptionprops<v></v></v></v></v>