import React, { HTMLAttributes, SFC } from 'react';
import Ink from '../Ink';
import ListItem from '../ListItem';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root' | 'active' | 'icon'>(theme => ({
  root: {
    background: theme.color('background'),
    cursor: 'pointer',
    maxWidth: '100%',
    padding: '0 0.5em',
    position: 'relative',
    transition: 'background-color 100ms',
    userSelect: 'none',
    whiteSpace: 'nowrap',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '2em',
    justifyContent: 'center',
    opacity: 0.7,
    '&:hover': {
      background: theme.color('grey', '50'),
    },
  },
  active: {
    background: theme.color('grey', '50'),
  },
  icon: {
    fontSize: '0.9em',
    marginLeft: '-0.2em',
    marginRight: '0.5em',
    position: 'relative',
  },
}));

export interface IconMenuItemProps extends HTMLAttributes<htmldivelement> {
  active?: boolean;
}

type Props = WithStyles<iconmenuitemprops, typeof="" styles="">;

const IconMenuItem: SFC<props> = ({
  niños
  clases,
  className,
  tema,
  ...rest
}) => (
  <listitem className="{cx(classes.root," className)}="" {...rest}="">
    {niños}
    <ink></ink>
  </listitem>
);

exportar por defecto withStyles(styles)(IconMenuItem);
</props></iconmenuitemprops,></htmldivelement>