import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(() => ({
  root: {
    alignItems: 'center',
    boxSizing: 'border-box',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    marginLeft: '0.14em',
    marginRight: '0.14em',
    minWidth: '1.6em',
    paddingLeft: '0.15em',
    paddingRight: '0.15em',
    position: 'relative',
    textAlign: 'center',
    userSelect: 'none',
    '&:first-child': {
      marginLeft: 0,
    },
    '&:last-child': {
      marginRight: 0,
    },
  },
}));

export type ToolbarItemProps = HTMLAttributes<htmldivelement>;

tipo Atrezzo = ConEstilos<toolbaritemprops, typeof="" styles="">;

const ToolbarItem: SFC<props> = ({
  niños,
  className,
  clases,
  tema,
  ...rest
}) => (
  <div className="{cx(classes.root," className)}="" {...rest}="">
    {niños}
  </div>
);

exportar por defecto withStyles(styles)(ToolbarItem);
</props></toolbaritemprops,></htmldivelement>