import React, { HTMLAttributes, PureComponent, ReactNode } from 'react';
import Scroller from '../Scroller';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root' | 'scroller'>(theme => {
  const height: any = (props: PickerProps) => props.height;
  const width: any = (props: PickerProps) => props.width;
  return {
    root: {
      height,
      width,
      display: 'flex',
      flexDirection: 'column',
    },
    scroller: {
      flex: 1,
    },
  };
});

// export type Option<v =="" {}=""> = {
//   key: string;
//   title: ReactNode;
//   value: V;
// };

// export type OrderedOptions<v =="" {}=""> = [
//   string | { title: ReactNode; key: string },
//   Option<v>[]
// ][];

exportar interfaz PickerProps extends HTMLLAttributes<htmldivelement> {
  footer?: ReactNode;
  header?: ReactNode;
  height?: number;
  width?: number;
}

type Props = WithStyles<pickerprops, typeof="" styles="">;

const defaultProps = Object.freeze({
  height: 300,
  width: 280,
});

class Picker extends PureComponent<props> {
  static defaultProps = defaultProps;

  render() {
    const {
      children,
      classes,
      className,
      footer,
      header,
      onScroll,
      ...rest
    } = this.props;
    return (
      <div className="{cx(classes.root," className)}="" {...rest}="">
        {cabecera}
        <scroller onScroll="{onScroll}" className="{classes.scroller}">
          {niños}
        </scroller>
        {footer}
      </div>
    );
  }
}

export default withStyles(styles)(Picker);
</props></pickerprops,></htmldivelement></v></v></v>