import { reduce } from 'lodash';

export type BreakpointName = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export type BreakpointMap<t> = { [K in BreakpointName]: T };

export type PartialBreakpointMap<t> = Parcial<breakpointmap<t>>;

const defaults = {
  xs: '0px',
  sm: '600px',
  md: '960px',
  lg: '1280px',
  xl: '1920px',
};

export default (input: PartialBreakpointMap<string> = {}) =>
  reduce(
    ['xs', 'sm', 'md', 'lg', 'xl'] as BreakpointName[],
    (acc, c) => ({
      ...acc,
      [c]: input[c] || defaults[c],
    }),
    {} as BreakpointMap<string>,
  );
</string></string></breakpointmap<t></t></t>