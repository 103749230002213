import React, { CSSProperties, SFC } from 'react';
import { MagnetAnchor } from '../Magnet';
import MagnetArrowBox from '../MagnetArrowBox';

type Props = {
  anchor?: MagnetAnchor;
  className?: string;
  domRef?: (el: HTMLElement | null) => void;
  style?: CSSProperties;
};

const PopupWrapper: SFC<props> = ({ children, className, ...rest }) => (
  <magnetarrowbox swap="" slide="" arrowBoxClassName="{className}" {...rest}="">
    {niños}
  </magnetarrowbox>
);

exportar por defecto PopupWrapper;
</props>