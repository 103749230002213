import { Link } from '@robotsnacks/icons';
import { Quill } from 'quill';
import React, { SFC } from 'react';
import ToolbarButton from '../ToolbarButton';
import { ToolbarInterface } from './ToolbarInterface';

export type LinkButtonProps = {
  quill: Quill;
  toolbar?: ToolbarInterface;
};

const LinkButton: SFC<linkbuttonprops> = ({ pluma, barra de herramientas }) => (
  <toolbarbutton onMouseDown="{e" ==""> {
      if (toolbar) {
        e.preventDefault();
        toolbar.createLink!().then(v => quill.format('link', v));
      }
    }}
    active={quill.hasFocus() && quill.getFormat().link}
  >
    <link>
  </toolbarbutton>
);

exportar por defecto LinkButton;
</linkbuttonprops>