import React, { HTMLAttributes, SFC, createElement } from 'react';
import SortableItem from '../SortableItem';
import { cx } from '../utils';

/**
 * Default tag to render if one isn't specified.
 */
const DEFAULT_TAG = 'div';

/**
 * Properties for the <sortable></sortable> componente.
 * @property order El orden de este elemento ordenable (establecido por el contenedor padre).
 * @property sortClassName Clase a aplicar mientras se ordena.
 * @property sortKey Clave de ordenación de este elemento.
 * @property tag La etiqueta a utilizar al mostrar este componente.
 */
exportar interfaz SortableProps extends HTMLLAttributes<any> {
  disabled?: boolean;
  order?: number;
  sortClassName?: string;
  sortKey: string;
  tag: keyof JSX.IntrinsicElements;
}

/**
 * Convenience component for quickly and easily creating a sortable item.
 * @param props Component properties.
 */
const Sortable: SFC<sortableprops> = ({
  className,
  desactivado,
  sortClassName,
  sortKey,
  etiqueta = DEFAULT_TAG,
  order,
  ...rest
}) => (
  <sortableitem sortKey="{sortKey}" order="{order}" disabled="{disabled}">
    {({ domRef, onMouseDown, onTouchStart, sorting, style }) =>
      createElement(tag, {
        ...rest,
        'data-sort-key': sortKey,
        className: cx(sorting && sortClassName, className),
        onMouseDown,
        onTouchStart,
        ref: domRef,
        style: {
          ...rest.style,
          ...style,
        },
      })
    }
  </sortableitem>
);

exportar por defecto Sortable;
</sortableprops></any>