import { ActionRedo } from '@robotsnacks/icons';
import { Quill } from 'quill';
import React, { SFC } from 'react';
import ToolbarButton from '../ToolbarButton';

export type RedoButtonProps = {
  quill: Quill;
};

const RedoButton: SFC<redobuttonprops> = ({ quill }) => (
  <toolbarbutton onMouseDown="{e" ==""> {
      e.preventDefault();
      (quill as any).history.redo();
    }}
  >
    <actionredo></actionredo>
  </toolbarbutton>
);

exportar por defecto RedoButton;
</redobuttonprops>