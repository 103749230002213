import React, { HTMLAttributes, SFC } from 'react';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root'>(() => ({
  root: {
    '& > *': {
      margin: '0.25em',
    },
    '& > *:first-child': {
      marginLeft: 0,
    },
    '& > *:last-child': {
      marginRight: 0,
    },
  },
}));

export interface ButtonRowProps extends HTMLAttributes<htmldivelement> {}

type Atrezzo = ConEstilos<buttonrowprops, typeof="" styles="">;

const ButtonRow: SFC<props> = ({
  niños
  clases,
  className,
  tema,
  ...rest
}) => (
  <div className="{cx(classes.root," className)}="" {...rest}="">
    {niños}
  </div>
);

exportar por defecto withStyles(styles)(ButtonRow);
</props></buttonrowprops,></htmldivelement>