import React, { HTMLAttributes, SFC } from 'react';
import ListItem from '../ListItem';
import { WithStyles, createStyles, withStyles } from '../styles';
import { cx } from '../utils';

const styles = createStyles<'root' | 'inner'>(theme => ({
  root: {
    boxSizing: 'border-box',
    display: 'inline-block',
    lineHeight: 2,
    margin: '1%',
    padding: '0',
    position: 'relative',
    width: '23%',
  },
  inner: {
    padding: '0 0.5rem',
  },
}));

export interface GridListItemProps extends HTMLAttributes<htmldivelement> {}

type Atrezzo = ConEstilos<gridlistitemprops, typeof="" styles="">;

const GridListItem: SFC<props> = ({
  niños
  clases,
  className,
  tema,
  ...props
}) => (
  <listitem className="{cx(classes.root," className)}="" {...props}="">
    <div className="{cx(classes.inner)}">{niños}</div>
  </listitem>
);

exportar por defecto withStyles(styles)(GridListItem);
</props></gridlistitemprops,></htmldivelement>